<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <article class="tile is-child box">
                    <h4 class="title">CME</h4>
                    <nav class="level">
                        <div class="level-right">
                            <p class="level-item">
                                <router-link :to="{ name: 'Cme', params: { id: 'new' }}" class="button is-success">
                                    Добавить
                                </router-link>
                            </p>
                        </div>
                    </nav>

                    <spinner v-show="loading"></spinner>
                    <table class="table is-striped" v-if="haveItems() && !loading">
                        <thead>
                        <tr>
                            <th>Ссылка</th>
                            <th>Баллы</th>
                            <th>Дата</th>
                            <th>Действие</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(cme, index) in items">
                            <td><a :href="cme.proof_url">{{ cme.proof_url }}</a> </td>
                            <td>{{ cme.points }}</td>
                            <td>{{ cme.date_from | dateTime }}</td>
                            <td class="is-icon is-active">
                                <a @click.prevent="remove(cme, index)">
                                    <i class="fa fa-remove" style="color: darkred;"></i> удалить
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <pagination
                            @change="changePage"
                            :total-pages="pagination.total"
                            :limit="pagination.limit"
                            :page="pagination.current"
                    ></pagination>

                </article>
            </div>
        </div>
    </div>
</template>

<script>
import cmeService from '../../services/cme'
import itemListViewMixin from '../../mixins/itemListView'

export default {
  mixins: [itemListViewMixin(cmeService, 'cme', true)],
  methods: {
    remove (cmeResource, $index) {
      this.removeConfirm(cmeResource, $index, `Вы действительно хотите удалить ?`)
    }
  }
}
</script>
