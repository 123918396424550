import BaseService from './baseService'

class CmeService extends BaseService {
  get url () {
    return 'cmes'
  }

  createModel () {
    return {
      date_from: '',
      points: '',
      proof_url: ''
    }
  }
}

export default new CmeService()
